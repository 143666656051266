import { useEffect } from "react";
import { useGetShiftListMutation } from "features/storeManagement/services/shiftsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowIcon from "assets/icons/ArrowIcon";
import { labels } from "features/locale/it-it";
import ShiftList from "components/modules/Shift/ShiftList";
import { Container } from "react-bootstrap";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary";

const TipologiaPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customerServiceType = location.state?.customerServiceType;
  const customerAddressId =
    location.state?.customerAddressIdData.customerAddress.id;


  const [
    getShiftList,
    {
      data: shiftListData,
      isLoading: shiftListDataIsLoading,
      error: shiftListDataError,
    },
  ] = useGetShiftListMutation({
    fixedCacheKey: fixedCacheKeys.shiftListData,
  });

  useEffect(() => {
    getShiftList({
      serviceType: customerServiceType,
      customerId: customerAddressId,
    });
  }, []);

  return (
    <ErrorBoundary errorMessage={shiftListDataError}>
      <Container
        fluid
        className="d-flex operatore-container flex-column align-items-start p-0 px-4 mb-0 mt-4"
      >
        <Suspense isLoading={shiftListDataIsLoading} fullPage>
          <p
            className="x-1 align-items-center container-fluid delete-prop"
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowIcon className="rotate-180deg blue-svg" />
            {labels.deleteShift}
          </p>
          <ShiftList data={shiftListData?.shiftList} />
        </Suspense>
      </Container>
    </ErrorBoundary>
  );
};

export default TipologiaPage;
